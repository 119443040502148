<template>
  <div>
    <div class="sort-dropdown">
      <div v-bind:class="{ isActive: dropdownActive }"
          class="sort-dropdown__title"
           @click.prevent="click"
           onmousedown="return false"
      >
        {{dropdownTitle}}
      </div>
      <div class="sort-dropdown__list" v-if="dropdownActive">
        <a v-for="item in dataLink"
           :href="'?sort=' + item.link"
           v-bind:class="{ isActive: item.active }"
           class="sort-dropdown__list-item"
        >{{ item.text }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSort',
  data () {
    return {
      dropdownTitle: '',
      dropdownActive: 0,
      getParam: '',
      dataLink: [
        {
          link: 'popular',
          text: "По популярности",
          active: 0
        },
        {
          link: 'price_asc',
          text: "Сначала дешевые",
          active: 0
        },
        {
          link: 'price_desc',
          text: "Сначала дорогие",
          active: 0
        }
      ]
    }
  },
  created()
  {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    this.getParam = params.get("sort")
    let isChek = 0

    if (this.getParam){
      this.dataLink = this.dataLink.map((value, index) => {
        if(value.link === this.getParam){
          value.active = 1
          this.dropdownTitle = value.text
          isChek = 1
        }
        return value
      });
    } else {
      this.dropdownTitle = this.dataLink[0].text
      this.dataLink[0].active = 1
      isChek = 1
    }
    if(!isChek) {
      this.dropdownTitle = this.dataLink[0].text
      this.dataLink[0].active = 1
    }

  },
  methods: {
    click(){
      this.dropdownActive = !this.dropdownActive
    },
    close (e) {
      if (!this.$el.contains(e.target)) {
        this.dropdownActive = false
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.close)
  },
  beforeDestroy () {
    document.removeEventListener('click',this.close)
  }
};
</script>
