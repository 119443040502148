var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isMobileMenu
      ? _c("div", { staticClass: "menu-mobile" }, [
          _c("div", { staticClass: "menu-mobile__container" }, [
            _c("div", { staticClass: "mobile-navigation" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "mobile-navigation__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "mobile-navigation__link",
                    attrs: { href: "/cart/" }
                  },
                  [
                    _c("div", { staticClass: "mobile-navigation__icon" }, [
                      _c("div", { staticClass: "quantity-pin" }, [
                        _c("span", { staticClass: "quantity-pin__text" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.cartCount) +
                              "\n                "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "i-icon i-icon__cart" })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mobile-navigation__text" }, [
                      _vm._v("Корзина")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _vm._m(3)
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-navigation__item" }, [
      _c(
        "a",
        { staticClass: "mobile-navigation__link", attrs: { href: "/" } },
        [
          _c("div", { staticClass: "mobile-navigation__icon" }, [
            _c("span", { staticClass: "i-icon i-icon__home" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mobile-navigation__text" }, [
            _vm._v("Главная")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-navigation__item" }, [
      _c(
        "a",
        {
          staticClass: "mobile-navigation__link",
          attrs: { href: "/catalog/" }
        },
        [
          _c("div", { staticClass: "mobile-navigation__icon" }, [
            _c("span", { staticClass: "i-icon i-icon__catalog" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mobile-navigation__text" }, [
            _vm._v("Каталог")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-navigation__item" }, [
      _c(
        "a",
        {
          staticClass: "mobile-navigation__link",
          attrs: { href: "/favorites/" }
        },
        [
          _c("div", { staticClass: "mobile-navigation__icon" }, [
            _c("span", { staticClass: "i-icon i-icon__favorite" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mobile-navigation__text" }, [
            _vm._v("Избранное")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-navigation__item" }, [
      _c(
        "a",
        {
          staticClass: "mobile-navigation__link",
          attrs: { href: "/personal/private/" }
        },
        [
          _c("div", { staticClass: "mobile-navigation__icon" }, [
            _c("span", { staticClass: "i-icon i-icon__user" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mobile-navigation__text" }, [
            _vm._v("Войти")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }