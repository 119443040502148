<template>
  <p>
    тетттааавв
  </p>
</template>

<script>
  export default {
    name: 'Hello',
    data() {
      return {};
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
