<template>
  <div>
    <div v-if="isMobileMenu" class="menu-mobile">
      <div class="menu-mobile__container">
        <div class="mobile-navigation">
          <div class="mobile-navigation__item">
            <a href="/" class="mobile-navigation__link">
              <div class="mobile-navigation__icon">
                <span class="i-icon i-icon__home"></span>
              </div>
              <div class="mobile-navigation__text">Главная</div>
            </a>
          </div>
          <div class="mobile-navigation__item">
            <a href="/catalog/" class="mobile-navigation__link">
              <div class="mobile-navigation__icon">
                <span class="i-icon i-icon__catalog"></span>
              </div>
              <div class="mobile-navigation__text">Каталог</div>
            </a>
          </div>
          <div class="mobile-navigation__item">
            <a href="/cart/" class="mobile-navigation__link">
              <div class="mobile-navigation__icon">
                <div class="quantity-pin">
                  <span class="quantity-pin__text">
                  {{cartCount}}
                  </span>
                </div>
                <span class="i-icon i-icon__cart"></span>
              </div>
              <div class="mobile-navigation__text">Корзина</div>
            </a>
          </div>
          <div class="mobile-navigation__item">
            <a href="/favorites/" class="mobile-navigation__link">
              <div class="mobile-navigation__icon">
                <span class="i-icon i-icon__favorite"></span>
              </div>
              <div class="mobile-navigation__text">Избранное</div>
            </a>
          </div>
          <div class="mobile-navigation__item">
            <a href="/personal/private/" class="mobile-navigation__link">
              <div class="mobile-navigation__icon">
                <span class="i-icon i-icon__user"></span>
              </div>
              <div class="mobile-navigation__text">Войти</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHello from './components/Hello';
import menuCatalogMobile from './components/menuCatalogMobile';


export default {
  name: 'MenuMobile',
  components: {
    VueHello,
    menuCatalogMobile
  },
  data () {
    return {
      isMobileMenu: false,
      windowWidth:  0,
      cartCount: 0
    }

  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();

    this.axios.get('/local/ajax/cart.php').then(response => (this.cartCount = response.data))

    $(".btn-to-cart").click( function() {
      this.getCountCart
    });

    $("body").on("click", ".btn-to-cart", this.getCountCart);



  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.isMobileMenu = this.windowWidth < 1025;
    },
    getCountCart() {
      this.axios.get('/local/ajax/cart.php').then(response => (this.cartCount = response.data))
    }

  }
};
</script>

<style lang="scss" scoped>
 .menu-mobile{
   position: fixed;
   bottom: 0;
   left: 0;
   z-index: 99;
   display: flex;
   flex-direction: column-reverse;
   width: 100%;
   height: 54px;
   box-sizing: border-box;
   color: #333;
   background: #fff;
   border-top: 0;
   transition: height .3s linear;
   box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.15);
   padding: 6px 0 7px;
   align-items: center;
 }
  .menu-mobile__container{
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    width: 100%;
  }
  .mobile-navigation{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .mobile-navigation__item{
    display: flex;
    font-size: 12px;
    letter-spacing: -.25px;
    position: relative;
  }
  .mobile-navigation__link{
    color: #091A2F;
  }
  .mobile-navigation__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -1px;
    position: relative;
  }
  .i-icon{
    width: 20px;
    height: 20px;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  .i-icon__home{
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.8537 7.48479C16.8532 7.4844 16.8528 7.48387 16.8524 7.48347L9.80113 0.432505C9.50058 0.131818 9.10098 -0.0336914 8.67593 -0.0336914C8.25088 -0.0336914 7.85129 0.131818 7.5506 0.432505L0.503061 7.47991C0.500687 7.48229 0.498181 7.48479 0.495939 7.48717C-0.12126 8.10793 -0.120205 9.11509 0.498972 9.73427C0.781855 10.0173 1.15534 10.1811 1.5548 10.1984C1.57116 10.1999 1.58751 10.2007 1.604 10.2007H1.8849V15.3897C1.8849 16.4166 2.72049 17.2521 3.74744 17.2521H6.50611C6.78583 17.2521 7.01253 17.0253 7.01253 16.7457V12.6774C7.01253 12.2089 7.3938 11.8277 7.86237 11.8277H9.4895C9.95807 11.8277 10.3392 12.2089 10.3392 12.6774V16.7457C10.3392 17.0253 10.5659 17.2521 10.8456 17.2521H13.6043C14.6314 17.2521 15.4668 16.4166 15.4668 15.3897V10.2007H15.7274C16.1523 10.2007 16.5519 10.0352 16.8528 9.7344C17.4726 9.1143 17.4729 8.10529 16.8537 7.48479V7.48479ZM16.1365 9.01829C16.0272 9.12762 15.8819 9.18789 15.7274 9.18789H14.9604C14.6807 9.18789 14.454 9.41459 14.454 9.69431V15.3897C14.454 15.8581 14.0729 16.2393 13.6043 16.2393H11.352V12.6774C11.352 11.6505 10.5166 10.8149 9.4895 10.8149H7.86237C6.83528 10.8149 5.99969 11.6505 5.99969 12.6774V16.2393H3.74744C3.279 16.2393 2.89774 15.8581 2.89774 15.3897V9.69431C2.89774 9.41459 2.67104 9.18789 2.39132 9.18789H1.63749C1.62958 9.18736 1.6218 9.18697 1.61375 9.18684C1.46288 9.1842 1.32138 9.12433 1.21535 9.01816C0.98983 8.79265 0.98983 8.42563 1.21535 8.19998C1.21548 8.19998 1.21548 8.19985 1.21561 8.19971L1.216 8.19932L8.26697 1.14861C8.37617 1.03929 8.52137 0.979148 8.67593 0.979148C8.83037 0.979148 8.97557 1.03929 9.08489 1.14861L16.1343 8.19787C16.1353 8.19892 16.1365 8.19998 16.1376 8.20103C16.3619 8.42694 16.3615 8.79318 16.1365 9.01829V9.01829Z' fill='%23091A2F'/%3E%3C/svg%3E%0A");
  }
  .i-icon__catalog{
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M19.7535 9.41638H3.81817C3.44524 9.41638 3.14294 9.77364 3.14294 10.2144C3.14294 10.6551 3.44524 11.0124 3.81817 11.0124H19.7535C20.1264 11.0124 20.4287 10.6551 20.4287 10.2144C20.4287 9.77364 20.1264 9.41638 19.7535 9.41638Z' fill='%23091A2F'/%3E%3Cpath d='M19.7535 3.03244H3.81817C3.44524 3.03244 3.14294 3.3897 3.14294 3.83043C3.14294 4.27116 3.44524 4.62843 3.81817 4.62843H19.7535C20.1264 4.62843 20.4287 4.27116 20.4287 3.83043C20.4287 3.3897 20.1264 3.03244 19.7535 3.03244Z' fill='%23091A2F'/%3E%3Cpath d='M19.7535 15.8003H3.81817C3.44524 15.8003 3.14294 16.1576 3.14294 16.5983C3.14294 17.039 3.44524 17.3963 3.81817 17.3963H19.7535C20.1264 17.3963 20.4287 17.039 20.4287 16.5983C20.4287 16.1576 20.1264 15.8003 19.7535 15.8003Z' fill='%23091A2F'/%3E%3Cellipse cx='0.785838' cy='16.5001' rx='0.785716' ry='0.785716' fill='%23091A2F'/%3E%3Cellipse cx='0.785838' cy='3.92863' rx='0.785716' ry='0.785716' fill='%23091A2F'/%3E%3Cellipse cx='0.785838' cy='10.2144' rx='0.785716' ry='0.785716' fill='%23091A2F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='20.4286' height='20.4286' fill='white' transform='translate(0.00012207)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  .i-icon__cart{
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.41722 17.643C8.70206 17.643 9.7436 16.6015 9.7436 15.3167C9.7436 14.0319 8.70206 12.9904 7.41722 12.9904C6.13242 12.9904 5.09088 14.0319 5.09088 15.3167C5.09088 16.6015 6.13242 17.643 7.41722 17.643ZM7.41726 16.5006C8.07108 16.5006 8.60114 15.9706 8.60114 15.3167C8.60114 14.6629 8.07108 14.1328 7.41726 14.1328C6.7634 14.1328 6.23334 14.6629 6.23334 15.3167C6.23334 15.9706 6.7634 16.5006 7.41726 16.5006Z' fill='%23091A2F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.7676 17.643C16.0524 17.643 17.094 16.6015 17.094 15.3167C17.094 14.0319 16.0524 12.9904 14.7676 12.9904C13.4828 12.9904 12.4413 14.0319 12.4413 15.3167C12.4413 16.6015 13.4828 17.643 14.7676 17.643ZM14.7676 16.5006C15.4215 16.5006 15.9515 15.9706 15.9515 15.3167C15.9515 14.6629 15.4215 14.1328 14.7676 14.1328C14.1138 14.1328 13.5837 14.6629 13.5837 15.3167C13.5837 15.9706 14.1138 16.5006 14.7676 16.5006Z' fill='%23091A2F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.3076 9.70572C18.9752 7.46968 19.969 4.07815 19.969 4.07815C19.9729 3.64799 19.6528 3.36821 19.3015 3.36821L4.27846 3.36986L3.93461 1.95844C3.55182 0.774533 2.38884 0.182587 1.57856 0.182587H0.591841C-0.197158 0.379902 -0.197171 1.36648 0.592124 1.53382C1.04422 1.53382 1.36165 1.5379 1.36165 1.5379C2.10536 1.53382 2.52608 1.90995 2.68366 2.55039L4.49548 9.98085C4.77569 10.8507 5.60955 11.8066 7.01324 11.8066C7.01324 11.8066 12.2466 11.8723 15.6754 11.8066C17.4038 11.7734 18.0165 10.6807 18.3076 9.70572ZM7.171 10.4561H15.7543C16.5587 10.4561 16.9303 9.71822 16.9303 9.60296L18.3786 4.71872H4.65L5.77665 9.36158C5.93424 10.0068 6.50727 10.4561 7.171 10.4561Z' fill='%23091A2F'/%3E%3C/svg%3E%0A");

  }
  .i-icon__favorite{
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.0274 1.16914C15.0202 -0.597932 12.0185 -0.30469 10.1548 1.68326L9.42926 2.46016L8.70369 1.68707C7.20099 0.0799524 4.16982 -0.887365 1.83106 1.16914C-0.481909 3.2104 -0.603451 6.87402 1.46644 9.08286L8.5932 16.6919C8.82155 16.9356 9.12356 17.0613 9.42557 17.0613C9.72759 17.0613 10.0296 16.9395 10.2579 16.6919L17.3847 9.08286C19.462 6.87402 19.3404 3.2104 17.0274 1.16914ZM16.545 8.23741L9.44399 15.8465L2.31355 8.23741C0.899245 6.72931 0.604599 3.85401 2.59714 2.09837C4.61547 0.316069 6.98738 1.60709 7.85658 2.53633L9.42926 4.21581L11.0019 2.53633C11.8564 1.62233 14.2504 0.327494 16.2614 2.09837C18.2502 3.85021 17.9593 6.7255 16.545 8.23741Z' fill='%23091A2F'/%3E%3C/svg%3E%0A");
  }
  .i-icon__user{
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M14.7545 11.1744C13.8131 10.233 12.6926 9.53616 11.4701 9.11431C12.7795 8.21252 13.6397 6.70329 13.6397 4.99672C13.6397 2.24157 11.3982 6.10352e-05 8.643 6.10352e-05C5.88785 6.10352e-05 3.64634 2.24157 3.64634 4.99672C3.64634 6.70329 4.50654 8.21252 5.8159 9.11431C4.59341 9.53616 3.47294 10.233 2.53157 11.1744C0.89915 12.8068 0.00012207 14.9772 0.00012207 17.2858H1.35057C1.35057 13.2647 4.62193 9.99339 8.643 9.99339C12.6641 9.99339 15.9354 13.2647 15.9354 17.2858H17.2859C17.2859 14.9772 16.3868 12.8068 14.7545 11.1744ZM8.643 8.64294C6.63248 8.64294 4.99679 7.00727 4.99679 4.99672C4.99679 2.98618 6.63248 1.35051 8.643 1.35051C10.6535 1.35051 12.2892 2.98618 12.2892 4.99672C12.2892 7.00727 10.6535 8.64294 8.643 8.64294Z' fill='%23091A2F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='17.2858' height='17.2858' fill='white' transform='translate(0.00012207)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  .quantity-pin{
    position: absolute;
    transform: translate(50%,-50%);
    display: flex;
    min-width: 17px;
    height: 17px;
    line-height: 15px;
    padding: 0 6px;
    font-weight: 600;
    text-align: center;
    font-family: system,-apple-system,BlinkMacSystemFont,'Segoe UI','Segoe WP',Roboto,Ubuntu,Oxygen,Cantarell,'Fira Sans','Helvetica Neue',Helvetica,'Lucida Grande','Droid Sans',Tahoma,'Microsoft Sans Serif',sans-serif;
    color: #fff;
    border-radius: 9px;
    background: #ed2233;
  }
</style>
