var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col" }, [
          _c("ul", { staticClass: "catalog-section-list-tile-list row mb-4" }, [
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_182" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/af7/af77e8743b18f4d51a08d9a592efd7cf.png')"
                      },
                      attrs: {
                        href: "/catalog/aksessuary/",
                        title: "Аксессуары"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: { href: "/catalog/aksessuary/" }
                      },
                      [
                        _vm._v(
                          "\n                            Аксессуары\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_31" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/a19/a1967eeacd7702cd26661826a39b673e.png')"
                      },
                      attrs: { href: "/catalog/vanny/", title: "Ванны" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: { href: "/catalog/vanny/" }
                      },
                      [
                        _vm._v(
                          "\n                                Ванны\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_41" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/242/24272987ef21bc878fdb0e1970a0d2ea.png')"
                      },
                      attrs: {
                        href: "/catalog/inzhenernaja-santekhnika/",
                        title: "Инженерная сантехника"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: { href: "/catalog/inzhenernaja-santekhnika/" }
                      },
                      [
                        _vm._v(
                          "\n                                Инженерная сантехника\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_68" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/d38/d386554ec8fb4a634f720b815009c5a0.png')"
                      },
                      attrs: { href: "/catalog/mebel/", title: "Мебель " }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: { href: "/catalog/mebel/" }
                      },
                      [
                        _vm._v(
                          "\n                                Мебель \t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_79" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/3f6/3f6f7c1975402fda8fdc726b280318f0.png')"
                      },
                      attrs: {
                        href: "/catalog/mojjki/",
                        title: "Мойки для кухни"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: { href: "/catalog/mojjki/" }
                      },
                      [
                        _vm._v(
                          "\n                                Мойки для кухни\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_84" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/8e8/8e8e7664b62f706868d3b05f7cad7de8.png')"
                      },
                      attrs: {
                        href: "/catalog/dushevye-kabiny-poddony/",
                        title: "Поддоны"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: { href: "/catalog/dushevye-kabiny-poddony/" }
                      },
                      [
                        _vm._v(
                          "\n                                Поддоны\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_85" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/e28/e281024a2679261a3d16184f65508bb9.png')"
                      },
                      attrs: {
                        href: "/catalog/polotentsesushitel/",
                        title: "Полотенцесушитель"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: { href: "/catalog/polotentsesushitel/" }
                      },
                      [
                        _vm._v(
                          "\n                                Полотенцесушитель\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_93" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/45e/45e957132942bffc2da1ae1fe0ba592c.png')"
                      },
                      attrs: {
                        href: "/catalog/sadovo-ogorodnyjj-inventar/",
                        title: "Товары для дачи и сада"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: { href: "/catalog/sadovo-ogorodnyjj-inventar/" }
                      },
                      [
                        _vm._v(
                          "\n                                Товары для дачи и сада\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_103" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/73d/73d6765131ee76b7fc67c6b6b36e6bde.png')"
                      },
                      attrs: {
                        href: "/catalog/sanfajans-i-komplektujushhie/",
                        title: "Санфаянс и комплектующие"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: {
                          href: "/catalog/sanfajans-i-komplektujushhie/"
                        }
                      },
                      [
                        _vm._v(
                          "\n                                Санфаянс и комплектующие\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_147" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/49e/49e38d3f3e9d8aadd03bf1fa04581ec6.png')"
                      },
                      attrs: {
                        href: "/catalog/smesiteli-i-komplektujushhie/",
                        title: "Смесители и комплектующие"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: {
                          href: "/catalog/smesiteli-i-komplektujushhie/"
                        }
                      },
                      [
                        _vm._v(
                          "\n                                Смесители и комплектующие\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass:
                  "col-6 col-sm-3 col-md-2 catalog-section-list-item",
                attrs: { id: "bx_1847241719_170" }
              },
              [
                _c(
                  "div",
                  { staticClass: "catalog-section-list-tile-img-container" },
                  [
                    _c("a", {
                      staticClass: "catalog-section-list-item-img",
                      staticStyle: {
                        "background-image":
                          "url('/upload/iblock/525/525e2ba6758b096d5309690cddb6b3a4.png')"
                      },
                      attrs: {
                        href: "/catalog/stroitelnyjj-material/",
                        title: "Строительный материал"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "catalog-section-list-item-inner" }, [
                  _c("h3", { staticClass: "catalog-section-list-item-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "catalog-section-list-item-link",
                        attrs: { href: "/catalog/stroitelnyjj-material/" }
                      },
                      [
                        _vm._v(
                          "\n                                Строительный материал\t\t\t\t\t\t\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }