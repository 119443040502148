import Swiper, {Navigation, Pagination, Thumbs,EffectFade} from 'swiper'
//

// configure Swiper to use modules


// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

// import styles bundle
// import 'swiper/css/bundle';
// // core version + navigation, pagination modules:
//  import SwiperCore, { Navigation, Pagination,Controller,Thumbs } from 'swiper/core';
// //
// //
// // // configure Swiper to use modules
//  SwiperCore.use([Navigation, Pagination, Controller,Thumbs]);
//
//  import 'swiper/swiper-bundle.css';

const brandsSlider = () => {
    $('.swiper-wrapper > .BrandsCategories__item').addClass('swiper-slide');
    const swiper = new Swiper('.swiper-container', {
        slidesPerView: 1.5,
        spaceBetween: 10,
        slidesPerColumn: 2,

        height: 95,
        preventClicks: false,
        effect: 'slide',
        preventClicksPropagation: false,
        navigation: {
            nextEl: '.brands-slider-next',
            prevEl: '.brands-slider-prev',
        },
        breakpoints: {
            // when window width is >= 325px
            325: {
                slidesPerView: 2.5,
                spaceBetween: 10
            },
            576: {
                slidesPerView: 3.5,
                spaceBetween: 15
            },
            768: {
                slidesPerView: 4,
                slidesPerColumn: 1,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 5,
                slidesPerColumn: 1,
                spaceBetween: 20,
            },
            1300: {
                slidesPerView: 5,
                slidesPerColumn: 1,
                spaceBetween: 32
            }
        },
        hiddenClass: 'swiper-button-hidden',
        disabledClass: 'swiper-button-disabled',
    });



    var prevBtnSlider = document.querySelector('.brands-slider-prev');

    if (swiper.isBeginning && prevBtnSlider != null) {
        prevBtnSlider.classList.add('swiper-button-hidden');
    };
    // swiper.on('slideChange', function () {
    //     prevBtnSlider.classList.remove('swiper-button-hidden');
    // });
};

//brandsSlider();

