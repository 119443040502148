var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "sort-dropdown" }, [
      _c(
        "div",
        {
          staticClass: "sort-dropdown__title",
          class: { isActive: _vm.dropdownActive },
          attrs: { onmousedown: "return false" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.click($event)
            }
          }
        },
        [_vm._v("\n      " + _vm._s(_vm.dropdownTitle) + "\n    ")]
      ),
      _vm._v(" "),
      _vm.dropdownActive
        ? _c(
            "div",
            { staticClass: "sort-dropdown__list" },
            _vm._l(_vm.dataLink, function(item) {
              return _c(
                "a",
                {
                  staticClass: "sort-dropdown__list-item",
                  class: { isActive: item.active },
                  attrs: { href: "?sort=" + item.link }
                },
                [_vm._v(_vm._s(item.text))]
              )
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }