<template>
    <div>
        <div class="header-region">
            {{cityName}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "GeoCity",
        data(){
            return {
                cityName : 'Чебоксары'
            }
        },
        mounted(){
            this.axios.get('/local/ajax/geo.php').then(response => (this.cityName = response.data))
        }
    }




</script>
<style scoped>

</style>