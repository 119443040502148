<template>
    <div>
        <div class="row mb-4">
            <div class="col">
                <ul class="catalog-section-list-tile-list row mb-4">							<li id="bx_1847241719_182" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                    <div class="catalog-section-list-tile-img-container">
                        <a href="/catalog/aksessuary/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/af7/af77e8743b18f4d51a08d9a592efd7cf.png');" title="Аксессуары"></a>
                    </div>
                    <div class="catalog-section-list-item-inner">
                        <h3 class="catalog-section-list-item-title">
                            <a class="catalog-section-list-item-link" href="/catalog/aksessuary/">
                                Аксессуары												</a>
                        </h3>
                    </div>
                </li>
                    <li id="bx_1847241719_31" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/vanny/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/a19/a1967eeacd7702cd26661826a39b673e.png');" title="Ванны"></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/vanny/">
                                    Ванны												</a>
                            </h3>
                        </div>
                    </li>
                    <li id="bx_1847241719_41" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/inzhenernaja-santekhnika/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/242/24272987ef21bc878fdb0e1970a0d2ea.png');" title="Инженерная сантехника"></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/inzhenernaja-santekhnika/">
                                    Инженерная сантехника												</a>
                            </h3>
                        </div>
                    </li>
                    <li id="bx_1847241719_68" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/mebel/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/d38/d386554ec8fb4a634f720b815009c5a0.png');" title="Мебель "></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/mebel/">
                                    Мебель 												</a>
                            </h3>
                        </div>
                    </li>
                    <li id="bx_1847241719_79" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/mojjki/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/3f6/3f6f7c1975402fda8fdc726b280318f0.png');" title="Мойки для кухни"></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/mojjki/">
                                    Мойки для кухни												</a>
                            </h3>
                        </div>
                    </li>
                    <li id="bx_1847241719_84" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/dushevye-kabiny-poddony/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/8e8/8e8e7664b62f706868d3b05f7cad7de8.png');" title="Поддоны"></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/dushevye-kabiny-poddony/">
                                    Поддоны												</a>
                            </h3>
                        </div>
                    </li>
                    <li id="bx_1847241719_85" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/polotentsesushitel/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/e28/e281024a2679261a3d16184f65508bb9.png');" title="Полотенцесушитель"></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/polotentsesushitel/">
                                    Полотенцесушитель												</a>
                            </h3>
                        </div>
                    </li>
                    <li id="bx_1847241719_93" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/sadovo-ogorodnyjj-inventar/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/45e/45e957132942bffc2da1ae1fe0ba592c.png');" title="Товары для дачи и сада"></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/sadovo-ogorodnyjj-inventar/">
                                    Товары для дачи и сада												</a>
                            </h3>
                        </div>
                    </li>
                    <li id="bx_1847241719_103" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/sanfajans-i-komplektujushhie/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/73d/73d6765131ee76b7fc67c6b6b36e6bde.png');" title="Санфаянс и комплектующие"></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/sanfajans-i-komplektujushhie/">
                                    Санфаянс и комплектующие												</a>
                            </h3>
                        </div>
                    </li>
                    <li id="bx_1847241719_147" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/smesiteli-i-komplektujushhie/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/49e/49e38d3f3e9d8aadd03bf1fa04581ec6.png');" title="Смесители и комплектующие"></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/smesiteli-i-komplektujushhie/">
                                    Смесители и комплектующие												</a>
                            </h3>
                        </div>
                    </li>
                    <li id="bx_1847241719_170" class="col-6 col-sm-3 col-md-2 catalog-section-list-item">
                        <div class="catalog-section-list-tile-img-container">
                            <a href="/catalog/stroitelnyjj-material/" class="catalog-section-list-item-img" style="background-image:url('/upload/iblock/525/525e2ba6758b096d5309690cddb6b3a4.png');" title="Строительный материал"></a>
                        </div>
                        <div class="catalog-section-list-item-inner">
                            <h3 class="catalog-section-list-item-title">
                                <a class="catalog-section-list-item-link" href="/catalog/stroitelnyjj-material/">
                                    Строительный материал												</a>
                            </h3>
                        </div>
                    </li>
                </ul>	</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "menuCatalogMobile"
    }
</script>

<style scoped>

</style>