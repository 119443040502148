import { render, staticRenderFns } from "./Hello.vue?vue&type=template&id=f0c664bc&scoped=true&"
import script from "./Hello.vue?vue&type=script&lang=js&"
export * from "./Hello.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0c664bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bitrix/ext_www/dev.komfortel.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f0c664bc')) {
      api.createRecord('f0c664bc', component.options)
    } else {
      api.reload('f0c664bc', component.options)
    }
    module.hot.accept("./Hello.vue?vue&type=template&id=f0c664bc&scoped=true&", function () {
      api.rerender('f0c664bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "custom/vue/components/Hello.vue"
export default component.exports