// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import axios from 'axios';

Vue.prototype.axios = axios;

/* eslint-disable no-new */
if(document.getElementById("vue-menu-mobile")) {
    const MenuMobile = require('./MenuMobile').default;

    Vue.config.productionTip = false;

    new Vue({
        el: '#vue-menu-mobile',
        components: {MenuMobile},
        template: '<MenuMobile/>',
    });
}


if(document.getElementById("vue-geo")) {
    const GeoCity = require('./components/GeoCity').default;

    Vue.config.productionTip = false;

    new Vue({
        el: '#vue-geo',
        components: {GeoCity},
        template: '<GeoCity/>',
    });
}

if(document.getElementById("app-sort")) {
    const ProductSort = require('./components/ProductSort').default;

    Vue.config.productionTip = false;
    new Vue({
        el: '#app-sort',
        components: {ProductSort},
        template: '<ProductSort/>',
    });
}



